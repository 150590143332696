.card {
    width: 212px;
    height: 301px;
    opacity: 1;
    flex-shrink: 0;
    border-radius: 12px;
    margin-bottom: 27px;
    cursor: pointer;
    margin-right: 25px;
    border: 3px solid transparent;
    &:hover {
        border: 3px solid #f42351 !important;
        height: 306px !important;
        border-radius: 17px;
    }
    &.activeCard {
        border: 3px solid #f42351 !important;
        height: 306px !important;
        border-radius: 17px;
    }
    .listImg {
        width: 100%;
        text-align: center;
        height: 301px;
        border-radius: 12px;
        position: relative;
        &:hover {
            box-shadow: 0px 0px 5px 1px lightgrey;
        }
        .mainImage {
            border-radius: 12px;
            height: 300px;
        }
        p {
            margin-bottom: 0px;
            &.webinarTitle {
                position: absolute;
                color: white;
                z-index: 2;
                top: 2px;
                font-size: 14px;
                left: 10px;
                font-weight: 600;
            }
            &.webinarTime {
                position: absolute;
                color: #fff;
                z-index: 2;
                bottom: 10px;
                font-size: 12px;
                left: 10px;
                font-weight: 600;
            }
        }
    }
    .statusTag {
        position: absolute;
        opacity: 99999;
        bottom: 30px;
        left: 10px;
        background: #f42351 !important;
        font-size: 10px;
        border-radius: 0 !important;
    }

    .iconTag {
        position: relative;
        opacity: 99999;
        top: -45px;
        left: 65px;
    }
}
