.customButton {
    width: 100%;
    border: none;
    border-radius: 12px;
    opacity: 1;
    height: 40px;
    background: linear-gradient(to right, #f42351, #f86181, #fe9743);
    color: #ffffff;
    outline: 0;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    &:hover {
        color: #ffffff;
        outline: 0;
    }
    &:focus {
        color: #ffffff;
        outline: 0;
    }
}

.svgColor {
    svg {
        color: red;
    }
}

.productImage {
    border-radius: 12px;
    width: 35px;
    height: 35px;
    margin-right: 10px;
}
.cardNotFound {
    h4 {
        font-size: 14px;
        font-weight: 600;

    }
    p {
        font-size: 12px;
    }
}

.cardListdata {
    margin: 5px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #ddd;
    &:last-child {
        border-bottom: none;
    }

}
.priceDetailLists {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        font-size: 13px;
        margin-bottom: 1px;
    }
}

.mainPrice { 
    border-bottom: 1px solid rgb(233, 233, 233);
    padding: 10px;
}

.totalPrice {
    display: flex;
    justify-content: space-between;
}

.priceHead {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    b {
        font-size: 14px;
    }
    span {
        color: crimson;
        cursor: pointer;
        font-size: 12px;
    }
}