.customButton {
    width: 100%;
    border: none;
    border-radius: 12px;
    opacity: 1;
    height: 40px;
    background: linear-gradient(to right, #f42351, #f86181, #fe9743);
    color: #ffffff;
    outline: 0;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    &:hover {
        color: #ffffff;
        outline: 0;
    }
    &:focus {
        color: #ffffff;
        outline: 0;
    }
}

.productImage {
    border-radius: 12px;
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.customFormGroup {
    .customFormControl {
        height: 45px !important;
        line-height: 24px !important;
        border-radius: 12px !important;
        border-color: #727272 !important;
    }
    margin-bottom: 15px !important;
}

.addressListDive {
    border-bottom: 1px solid gray;
    cursor: pointer;
    display: flex;
    h4 {
        text-transform: capitalize;
        font-size: 15px;
        font-weight: 600;
        word-wrap: break-word;
    }
    p {
        font-size: 15px;
        word-wrap: break-word;
        margin: 0;
        margin-bottom: 5px;
    }
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    &.customizeDropDown {
        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
            border: none !important;
        }
    }
}
.cardNotFound {
    h4 {
        font-size: 14px;
        font-weight: 600;

    }
    p {
        font-size: 12px;
    }
}

.cardListdata {
    margin-bottom: 10px;
}