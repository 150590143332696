.prodListHeading {
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    font-size: 20px;
    text-align: center;
    font-family: Source Sans Pro;
}

.redgLink {
    font-size: 11px;
    cursor: pointer;
    margin: 0;
    margin-top: 10px;
    // margin-bottom: 15px;
    color: #424242;
    font-weight: bold;
}

.customizeGoogle {
    border: none !important;
    color: #F63451 !important;
    box-shadow: none !important;
    padding: 0 !important;
    font-size: 50px !important;
    line-height: 0 !important;
    background: none !important;
    margin-right: 40px;
    div {
        display: none;
    }
    span {
        padding: 0 !important;
    }
}

.customizeFacebook {
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    line-height: 0 !important;
    background: none !important;
}
.authFooter {
    display: flex;
    justify-content: space-around;
    align-items: center;
}