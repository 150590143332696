.card {
    width: 100px;
    height: 150px;
    opacity: 1;
    flex-shrink: 0;
    border-radius: 12px;
    margin-bottom: 27px;
    cursor: pointer;
    margin-right: 25px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        border: 3px solid #f42351 !important;
        height: 155px !important;
        border-radius: 17px;
    }
    &.activeCard {
        border: 3px solid #f42351 !important;
        height: 155px !important;
        border-radius: 17px;
    }
    .listImg {
        width: 100%;
        text-align: center;
        height: 100%;
        border-radius: 12px;
        position: relative;;
        &:hover {
            box-shadow: 0px 0px 5px 1px lightgrey;
        }
        .mainImage {
            border-radius: 12px;
            height: 100%;
        }
        .webinarTitle {
            text-align: left;
            position: absolute;
            color: white;
            z-index: 2;
            margin-bottom: 0px;
            font-size: 12px;
            margin-left: 4px;
            font-weight: 600;
            width: 95px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .webinarTime {
            position: absolute;
            color: #fff;
            z-index: 2;
            margin-bottom: 0px;
            font-size: 12px;
            margin-left: 4px;
            margin-top: -20px;
            font-weight: 600;
        }
    }
    .statusTag {
        position: absolute;
        opacity: 1;
        bottom: 20px;
        left: 5px;
        background: #f42351 !important;
        font-size: 10px;
        border-radius: 0 !important;
    }

    .iconTag {
        width:40px;
        position: absolute;
        opacity: 99999;
        bottom: 0px;
        right: 0px;
    }
    .webinartitle {
        width: 95px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
    }
}
