.customFormGroup {
    .customFormControl {
        height: 45px !important;
        line-height: 24px !important;
        border-radius: 12px !important;
        border-color: #727272 !important;
        border: 1px solid #727272 !important;
    }
    margin-bottom: 15px !important;
}

.addressListDive {
    cursor: pointer;
    display: flex;
    h4 {
        text-transform: capitalize;
        font-size: 15px;
        font-weight: 600;
        word-wrap: break-word;
    }
    p {
        font-size: 15px;
        word-wrap: break-word;
        margin: 0;
        margin-bottom: 5px;
    }
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    &.customizeDropDown {
        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
            border: none !important;
        }
    }
}

.customButton {
    border-radius: 16px;
    opacity: 1;
    color: white;
    width: 100%;
    height: 40px;
    background: #f42351;
    // margin-left: 10px;
    &:hover {
        color: white;
    }
}

.addressList {
    border-bottom: 1px solid #ddd;
    &:last-child {
        border-bottom: none;
    }
}
.selectedRow {
    background: #f42351 !important;
    color: #fff !important;
    svg {
        color: #fff !important;
    }
    span {
        color: #fff !important;
    }
}

.svgColor {
    svg:nth-child(1) {
        color: #f42351;
    }
}
