.mobileFooter {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left:0px;
    height: 65px;
    background-color: #fff;
    box-shadow: 0px -6px 3px -5px rgb(165, 156, 156);
    ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-top: 2px;
        
        li {
            list-style: none;
            display: inline;
            padding: 6px 0px;
            text-align: center;
            p {
                margin-bottom: 2px;
            }
            a {
                text-decoration: none;
                color: #424242;
                justify-content: space-around;
                &.active {
                    color: #F42351;
                }
                span {
                    padding: 0 10px;
                }
            }
            svg {
                font-size: 30px;
            }
        }
    }
}
.mobileSection {
    height: 100vh;
    width: 100%;
    padding: 0px;
    overflow: hidden;
    position: relative;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0 auto;
    min-height: -webkit-fill-available;
}