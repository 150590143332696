.prodImage {
    width: 100%;
    height: 120px;
    box-shadow: 0px 3px 8px #00000029;
    border: 2px solid #ffffff;
    border-radius: 10px;
    opacity: 1;
    margin-bottom: 10px;
}
.prodName {
    letter-spacing: 0px;
    color: #232530;
    text-transform: uppercase;
    opacity: 1;
    text-align: center;
    font-size: 10px;
    word-wrap: break-word;
    font-weight: 100;
    margin-top: 8px;
}

.custombadge {
    position: relative;
    top: 70px;
    left: -5px;
    box-shadow: 0px 4px 16px #455b6329;
    border-radius: 8px !important;
    opacity: 1;
    background: #ffffff;
    color: #232530;
}
@media only screen and (max-width: 600px) {
    width: 60px;
    .prodImage {
        height: 80px;
    }
}