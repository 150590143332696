.form-group {
    .form-control {
        height: 45px !important;
        line-height: 30;
        border-radius: 12px !important;
        border-color: #727272 !important;
    }
    margin-bottom: 10px;
}

.customButton {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
    height: 41px;
    background: linear-gradient(to right, #f42351, #f86181, #FE9743);
    color: #ffffff;
    outline: 0;
    text-transform: uppercase;
    font-size: 15px;
    &:hover {
        color: #ffffff;
        outline: 0;
    }
    &:focus {
        color: #ffffff;
        outline: 0;
    }
}
