.headText {
    letter-spacing: 0px;
color: #424242;
opacity: 1;
font-size: 20px;
font-family: Source Sans Pro;
font-weight: bold;
}
.copyBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    background: #c9c7c7;
    svg {
        cursor: pointer;
        font-size: 24px;
    }
}
.successCopy {
    position: relative;
    margin: auto;
    // bottom: 50px;
    // left: 43%;
    width: 200px;
    padding: 15px 40px;
    color: rgb(5, 129, 11);
    background: #fff;
    z-index: 9999;
    text-align: center;
    border-radius: 4px;
}