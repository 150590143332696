.scrollBlock {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.homeblock {
    // margin-left: -11px;
    height: auto;
    background-size: cover !important;
    // position: absolute;
    width: 100%;
//     filter: blur(8px);
//   -webkit-filter: blur(8px);
  backdrop-filter: saturate(180%) blur(50px);
  .bgWrapper {
    // background-color: rgb(0,0,0); /* Fallback color */
    // background-color: rgba(0,0,0, 0.5); /* Black w/opacity/see-through */
    position: relative;
    // height: auto;
    z-index: 2;
    margin: auto;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover; // use "cover" to avoid distortion
        position: absolute;
        background: #000;
    }

        .chatBlock {
            width: 300px;
            height: 350px;
            position: fixed;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            left: 10px;
            bottom: 10px;
            @media only screen and (max-width: 600px) {
                width: 250px;
                left: 10px;
                bottom: 70px;
                &.chatBlockIos {
                    bottom: 20px;
                }
            }
            // background: rgba(255, 255, 255, 0.9);
        }
        .proDuctListBlock {
            position: fixed;
            width: 100%;
            bottom: 60px;
            z-index: 999;
            &.prodDetailsb {
                bottom: 55px;
            }
            &.proDuctListBlockIos1 {
                bottom: 0px;
            }
            &.proDuctListBlockIos {
                bottom: 0px;
            }
            // height: 85vh;
            .showHideMark {
                margin-bottom: 5px;
                h1 {
                    height: 6px;
                    width: 30%;
                    cursor: pointer;
                    margin: auto;
                    background: #ffffff;
                    border-radius: 4px;
                }
            }
            .commonFloatingBlock {
                background: white;
                border-radius: 12px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                opacity: 1;
                @media (max-width: 600px) {
                    width: auto;
                }
            }
            .webinarProduct {
                position: absolute;
                right: 10px;
                bottom: 10px;
                .listMainBlock {
                    width: 100px;
                    .prodImage {
                        width: 100%;
                        height: 120px;
                        box-shadow: 0px 3px 8px #00000029;
                        border: 2px solid #ffffff;
                        border-radius: 10px;
                        opacity: 1;
                        margin-bottom: 10px;
                    }
                    .prodName {
                        letter-spacing: 0px;
                        color: #232530;
                        text-transform: uppercase;
                        opacity: 1;
                        text-align: center;
                        font-size: 10px;
                        word-wrap: break-word;
                        font-weight: 100;
                        margin-top: 8px;
                    }
                
                    .custombadge {
                        position: relative;
                        top: 70px;
                        left: -5px;
                        box-shadow: 0px 4px 16px #455b6329;
                        border-radius: 8px !important;
                        opacity: 1;
                        background: #ffffff;
                        color: #232530;
                    }
                }
                .customPlusProduct {
                    background: #fff;
                    border-radius: 3px;
                    position: absolute;
                    top: 14px;
                    right: -5px;
                    height: 19px;
                    padding: 1px;
                    svg {
                        margin-top: -10px;
                        color: #F42351;
                    }
                }
            }
        }
        .profileMainBlock {
            position: absolute;
            top: 15px;
            left: 10px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            .dataBlock {
                width: 70%;
                .storeDetail {
                    display: flex;
                    align-items: center;
                    .profileImage {
                        border-radius: 4px;
                        width: 36px;
                        height: 36px;
                        margin-right: 10px;
                    }
                    .profileBlock {
                        color: #fff;
                        h6 {
                            margin-bottom: 2px;
                            font-weight: 600;
                        }
                        p {
                            margin-bottom: 0px;
                            font-size: 12px;
                        }
                    }
                }
                
                svg {
                    color: #fff;
                    cursor: pointer;
                    margin-top: -6px;
                    width: 20px;
                    height: 20px;
                }
                .likesAndLiveuser {
                    width: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #ffffff;
                    .LiveUsers {
                        display: flex;
                        align-items: center;
                        svg {
                            color: #3fecdc;
                        }
                    }
                    .totalLikes {
                        display: flex;
                        align-items: center;
                        svg {
                            color: #f42351;
                        }
                    }
                }
            }
            .campaignlayout {
                display: flex;
                width: 30%;
                padding-right: 20px;
                justify-content: flex-end;
                svg {
                    color: #fff;
                    cursor: pointer;
                    width: 25px;
                    height: 25px;
                }
            }
        }
        .profileExpandedBlock {
            position: absolute;
            padding: 0px 10px;
            top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            .dataBlock {
                display: flex;
                align-items: center;
                .profileImage {
                    border-radius: 4px;
                    width: 36px;
                    height: 36px;
                    margin-right: 10px;
                }
            }
            
        }
        .minimumIcons {
            position: absolute;
            padding: 0px 10px;
            // bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 99%;
            svg {
                color: #fff;
                cursor: pointer;
                margin-top: -6px;
                width: 22px;
                height: 22px;
            }
            // &.manageIconBottom {
            //     bottom: 40px;
            // }
            &.manageTextBottom {
                bottom: 15px;
            }
        }
        .videoControls {
            position: absolute;
            right: 0px;
            top: 20%;
            ul {
                list-style: none;
                margin: 0px;
                padding: 0px;
                li {
                    background: rgba(61, 55, 55, 0.4);
                    margin-bottom: 0px;
                    border-radius: 0px;
                    padding: 15px;
                    &:first-child {
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                    }
                    &:last-child {
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                    svg {
                        color: #fff;
                        cursor: pointer;
                        width: 25px;
                        height: 25px;
                        margin-top: -10px;
                    }
                }
            }
        }
        &.smallVdo {
            video {
                // object-fit: none;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
            }
            .titleDescription {
                color: #fff;
                position: absolute;
                bottom: 0px;
                left: 10px;
                
                h6 {
                    margin-bottom: 5px;
                    letter-spacing: 0.4;
                }
                p {
                    font-size: 12px;
                    letter-spacing: 0.2;
                }
            }
        }
    }
}

.headings {
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
    .custombadge {
        background: #f42351 !important;
        border-radius: 16px !important;
        opacity: 1;
        letter-spacing: 0px;
        color: #fcfcf7;
        opacity: 1;
        font-size: 15px;
        font-weight: bold;
        margin-left: 10px;
        padding: 5px 15px;
        cursor: pointer;
    }
}

.listDiv {
    width: 100%;
    display: flex;
    overflow-x: auto;
    // gap: 25px;
    padding: 5px 0 0 2px;
    .cards-block {
        margin-right: 20px;
    }
}

.manageScroller::-webkit-scrollbar {
    height: 5px;
    @media (max-width: 600px) {
        width: 0px;
        height: 0px;
    }
}

.loader {
    background: url(../../assets/icons/loader.gif);
    background-position: center;
    background-repeat: no-repeat;
    height: 100px;
}

.customButton {
    border-radius: 16px;
    opacity: 1;
    color: white;
    width: 81px;
    height: 31px;
    background: #f42351;
    margin-left: 10px;
    &:hover {
        color: white;
    }
    @media (max-width: 600px) {
        float: right;
        margin: 0;
        margin-right: 8px;
    }
}

video::-webkit-media-controls-fullscreen-button {
    display: none;
}
video::-webkit-media-controls-play-button {
    @media only screen and (max-width: 600px) {
        display: none;
    }
}
video::-webkit-media-controls-timeline {
    // @media only screen and (max-width: 600px) {
    //     display: none;
    // }
}
video::-webkit-media-controls-current-time-display{
    @media only screen and (max-width: 600px) {
        display: none;
    }
}
video::-webkit-media-controls-time-remaining-display {
    display: none;
}
video::-webkit-media-controls-mute-button {
    display: none;
    &:hover {
        display: none;
    }
}
video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
}
video::-webkit-media-controls-volume-slider {
    display: none;
}

@media only screen and (max-width: 600px) {
    
}

.dummyClikableBox {
    width: 96%;
    margin: auto;
    position: absolute;
    top: 2%;
    left: 2%;
    height: 85%;
    z-index: 997;
    background: transparent;
}

.hearts {
    svg {
      color: #f42351;
    }
    position: absolute;
    bottom: 42px;
    left: 53%;
    z-index: 10;
    width: 5%;
    .heart {
        position: absolute;
        right: 20px;
        height: 20%;
        min-height: 25px;
        width: 20%;
        min-width: 25px;
        opacity: 1;
        // animation: floating-heart 3s linear forwards;
        animation: floating-heart 3s ease-in-out 0s 1;
       
    }
    .opacityHeart {
        opacity: 0;
    }
    // @keyframes floating-heart {
    //     0% { 
    //         opacity: 1;
    //         transform: scale(0.3);
    //         transform: translate3d(0, 0, 0);
    //       }
    //       50% {
    //         opacity: 1;
    //         transform: scale(1.6);
    //       } 
    //       100% {
    //         opacity: 0;
    //         transform: scale(0);
    //         transform: translate3d(-130px, -95vh, 0);
    //       }
    // }
    @keyframes floating-heart {
        0% {
          opacity: 1;
           transform: translateY(-30px); 
        }
    //     50%{
    //    transform: scale(5);
    //     }
        100% {
          opacity: 0;
           transform: translate3d(-130px, -95vh, 0) scale(3);
        }
      }
  }