.headerblock {
    position: relative;
    height: 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
        // position: absolute;
        a {
            text-decoration: none;
            // background-image: linear-gradient(104deg, var(---3da4ab) 0%, #F46692 48%, #FFC444 100%) 0% 0% no-repeat padding-box;
            background-image: linear-gradient(97deg, #f42351, #fc9a47 75%, #ffc444);
            font-size: 30px;
            font-weight: 900;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -o-object-fit: fill;
            object-fit: fill;
        }
    }
    .headerMenus {
        // padding-top: 10px;
        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            
            li {
                list-style: none;
                display: inline;
                padding: 0px 10px;
                a {
                    text-decoration: none;
                    color: #424242;
                    display: flex;
                    justify-content: space-around;
                    &.active {
                        color: #F42351;
                    }
                    span {
                        padding: 0 10px;
                    }
                }
            }
            @media only screen and (max-width: 600px) {
                justify-content: flex-end;
                li {
                    padding: 0px 20px;
                }
            }
        }
    }
    .logoutBox {
        svg {
            color: #F42351;
            font-size: 30px;
            cursor: pointer;
        }
    }
    .logInOutBox{
        display:flex;
        gap: 5px;
        margin-right:20px;
        margin-top:10px;
        justify-items:center;
        cursor:pointer
    }
    .logInOutBoxText{
        font-size: 16px;
        margin-top: 5px;
    }
}