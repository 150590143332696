.cartBlock {
  // width: 320px;
  background-color: #fff;
  // box-shadow: 0px 1px 6px -1px #777;
  height: auto;
  position: sticky;
  top: 100px;
  z-index: 99;
  // padding: 5px;
  // border: 1px solid #eee;
  // box-shadow: 1px 5px 1px #aaa;
  .cartHeader {
    color: var(--portal-dark-green);
    // border-bottom: 1px solid #eee;
    font-size: 24px;
    // padding: 10px 15px;
    margin-bottom: 15px;
    letter-spacing: 1px;
    font-weight: 600;
  }
  .itemContainer {
    background-color: #fff;
    box-shadow: var(--box-shadow);
    .itemList {
      margin-bottom: 4px;
      border-bottom: 1px solid #f1f1f1;
      margin-left: 0px;
      width: 100%;
      padding-bottom: 10px;
      &:last-child {
        border-bottom: none;
      }
      img {
        width: 50px;
        margin-right: 5px;
      }
      .timeslotTime {
        color: #555;
        font-size: 10px;
        padding-left: 17px;
      }
      .quantityAndAction {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 35px;
        width: 65px;
        float: right;
        svg {
          cursor: pointer;
        }
        .quantityBlock {
          font-weight: 500;
        }
        .actionBlock {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 35px;
          img {
            width: 20px;
            cursor: pointer;
          }
          // button {
          //     border: none;
          //     background: none;
          //     &.addItem {

          //     }
          //     &.removeItem {

          //     }
          // }
        }
      }
      .itemPrice {
        padding-right: 4;
        font-size: 19;
        font-weight: 700;
        color: #E86E00;
      }
      .itemDetails {
        h4 {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 5px;
        }
        p {
          font-size: 10px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .itemContainer::-webkit-scrollbar {
    width: 4px;
    }
  .svgColor {
      svg:first-of-type {
          color: red;
      }
  }
  .paymentButtonDiv {
    // display: flex;
    // justify-content: space-between;
    // align-items: end;
    padding: 10px 0px;
    border-top: 1px solid #eee;

    p {
        font-weight: 600;
        width: 100%;
        text-align: right;
        &.paymentBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          .paymentButton {
            background: #e86e00;
            border: 1px solid #e86e00;
            color: #fff;
            border-radius: 4px;
            font-size: 15px;
            width: 100%;
            letter-spacing: 1px;
            text-align: center;
            padding: 8px 0px 11px 0px;
            text-decoration: none;
            box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #E86E00;
            // width: 100%;
            // @media only screen and (max-width: 1200px) {
            //     padding: 3px 35px 4px;
            //   }
            //   @media only screen and (max-width: 1048px) {
            //     padding: 3px 30px 4px;
            //   }
            //   @media only screen and (max-width: 600px) {
            //     padding: 3px 30px 4px;
            //   }
          }
        }
    }
  }
}
