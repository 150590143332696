@font-face {
  font-family: 'CustomFont';
  // src: url("./assets/fonts/SourceSansPro-Black.ttf") format('truetype');;
  // src: url("./assets/fonts/SourceSansPro-BlackItalic.ttf") format('truetype');;
  // src: url("./assets/fonts/SourceSansPro-Bold.ttf") format('truetype');;
  // src: url("./assets/fonts/SourceSansPro-BoldItalic.ttf") format('truetype');;
  // src: url("./assets/fonts/SourceSansPro-ExtraLight.ttf") format('truetype');;
  // src: url("./assets/fonts/SourceSansPro-ExtraLightItalic.ttf") format('truetype');;
  // src: url("./assets/fonts/SourceSansPro-Italic.ttf") format('truetype');;
  // src: url("./assets/fonts/SourceSansPro-Light.ttf") format('truetype');;
  // src: url("./assets/fonts/SourceSansPro-LightItalic.ttf") format('truetype');;
  src: url('./assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
  // src: url("./assets/fonts/SourceSansPro-SemiBold.ttf") format('truetype');;
  // src: url("./assets/fonts/SourceSansPro-SemiBoldItalic.ttf") format('truetype');;
}
@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-VariableFont_wght.ttf') format('truetype');
}
html {
  height: -webkit-fill-available;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: 'CustomFont';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
}

@media only screen and (max-width: 600px) {
  body {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Manrope' !important;
}

p {
  font-family: 'CustomFont';
}

code {
  font-family: 'CustomFont';
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.form-group {
  .form-control {
    line-height: 24px !important;
  }
}
.kep-login-facebook {
  padding: 5px !important;
  font-size: 12px !important;
  margin-top: 24px !important;
}

button {
  &.btn-custom-des {
    padding: 1px 5px;
    border-radius: 4px;
    background: #f42351;
  }
}

.mobileLayout{
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
  max-width: 30%;
}

.MuiDialog-paper {
  margin: 32px auto !important;
  position: relative;
  overflow-y: hidden !important;
}